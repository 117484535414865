import * as React from "react";
import PropTypes from "prop-types";

export const Iframe = React.forwardRef((props, ref) => {
  return (
    <object
      style={{ height: "100%", width: "100%" }}
      data={props.launchUrl}
      type="text/html"
      id="game"
      ref={ref}
    />
  )
})

Iframe.displayName = "Iframe"

Iframe.propTypes = {
  launchUrl: PropTypes.string.isRequired,
};
